// 发布任务
<template>
<div class="audit_detail_container">
  <!-- 标题 -->
  <div class="title_container">
    <div class="back_to_task" @click="backToTaskHall">
      <i class="el-icon-arrow-left"></i>
      <span class="tw-ml-[4px]">返回上一页</span>
    </div>
    <div class="task_title">发布任务</div>
  </div>
  <!-- 表单容器 -->
  <el-form
    ref="form"
    label-position="left"
    :model="form"
    class="task_form_container"
    label-width="110px"
    :hide-required-asterisk="true"
    :rules="rules">
    <el-form-item
      label="任务标题"
      prop="title">
      <el-input
        type="text"
        size="small"
        v-model.trim="form.title"
        style="width:320px;">
      </el-input>
    </el-form-item>
    <el-form-item label="任务类型">
      单人任务
    </el-form-item>
    <el-form-item
      label="任务奖励"
      prop="amount">
      <el-input
        type="text"
        size="small"
        style="width:320px;"
        v-model.trim="form.amount">
      </el-input>
      <span style="margin-left: 10px;">元</span>
    </el-form-item>
    <el-form-item
      label="任务截止时间"
      prop="deadline">
      <el-date-picker
        v-model="form.deadline"
        type="date"
        size="small"
        value-format="yyyy-MM-dd"
        style="width: 320px;"
        placeholder="选择日期"
        @change="dateChange">
      </el-date-picker>
      <span class="tip">到截止时间后，任务无法在大厅展示，已接单任务可继续完成</span>
    </el-form-item>
    <el-form-item
      label="任务时限"
      prop="timeLimit">
      <el-select v-model="form.timeLimit"
        :disabled="form.deadline === ''"
        placeholder="请选择任务时限"
        size="small"
        style="width:320px;">
        <el-option
          v-for="item in timeLimitOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="任务级别"
      prop="level">
        <el-select
          placeholder="请选择任务级别"
          v-model="form.level"
          size="small"
          style="width:320px;">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item
      label="任务描述"
      prop="remark">
      <el-input type="textarea"
        :autosize="{ minRows: 5, maxRows: 8}"
        v-model="form.remark"
        maxlength="300"
        show-word-limit
        placeholder="请输入内容(限300字)"
        style="width:600px;">
      </el-input>
    </el-form-item>
    <el-form-item
      label="任务要求"
      prop="require">
      <el-input type="textarea"
        :rows="3"
        v-model="form.require"
        style="width:600px;"
        :autosize="{ minRows: 5, maxRows: 8}"
        maxlength="300"
        placeholder="请输入内容(限300字)"
        show-word-limit>
      </el-input>
    </el-form-item>
    <el-form-item
      label="相关附件"
      prop="attachmentIds">
      <el-upload
        class="upload-demo"
        :action="uploadUrl"
        style="width:370px"
        :on-remove="handleRemove"
        :on-success="fileSuccess"
        :before-remove="beforeRemove"
        multiple
        :limit="3"
        :file-list="form.attachmentIds"
        :on-exceed="handleExceed"
        :before-upload="beforeAvatarUpload">
        <el-button
          size="small"
          type="primary">
          点击上传
        </el-button>
      </el-upload>
    </el-form-item>
  </el-form>
  <!-- 按钮 -->
  <div class="release_button">
    <el-button
      type="primary"
      size="small"
      @click="publishClick" >
      发布任务
    </el-button>
  </div>
</div>

</template>

<script>
import api from '@/api'
import { getNowFormatDate, handleDate, toTimestamp } from '@/utils/dateTime'
export default {
  components: {
  },
  data () {
    return {
      isReadyHaveTask: true,
      form: {
        title: '', // 任务标题
        taskType: 0, // 任务类型
        amount: '', // 任务奖励
        timeLimit: '', // 任务时限
        level: '', // 任务级别
        remark: '', // 任务描述
        require: '', // 任务要求
        deadline: '', // 任务截止时间
        attachmentIds: []
      },
      options: [{
        value: 0,
        label: '普通'
      },
      {
        value: 1,
        label: '一般'
      },
      {
        value: 2,
        label: '复杂'
      },
      {
        value: 3,
        label: '困难'
      }],
      btnLoading: false,
      rules: {
        title: [
          { required: true, message: '请输入任务标题', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入任务金额', trigger: 'blur' },
          { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,})?$/, message: '请输入任务金额', trigger: 'blur' }
        ],
        timeLimit: [
          { required: true, message: '请输入任务时限', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请输入任务等级', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入任务描述', trigger: 'blur' }
        ],
        require: [
          { required: true, message: '请输入任务要求', trigger: 'blur' }
        ],
        taskType: [
          { required: true, message: '请输入任务类型', trigger: 'blur' }
        ],
        deadline: [
          { required: true, message: '请选择任务截止时间', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    userInfo () {
      return JSON.parse(localStorage.getItem('userInfo'))
    },
    amountMoney () {
      return this.userInfo.amount
    },
    // 重新发布任务
    taskId () {
      return this.$route.params.taskId
    },
    // 根据任务结束时间选择时间限制
    timeLimitOptions () {
      const curTime = getNowFormatDate()
      let options = [
        { value: 1, label: '1天' },
        { value: 3, label: '3天' },
        { value: 5, label: '5天' },
        { value: 10, label: '10天' },
        { value: 30, label: '30天' }
      ]
      if (toTimestamp(handleDate(curTime, 30)) <= toTimestamp(this.form.deadline)) {
        options = [
          { value: 1, label: '1天' },
          { value: 3, label: '3天' },
          { value: 5, label: '5天' },
          { value: 10, label: '10天' },
          { value: 30, label: '30天' }
        ]
      } else if (toTimestamp(handleDate(curTime, 10)) <= toTimestamp(this.form.deadline)) {
        options = [
          { value: 1, label: '1天' },
          { value: 3, label: '3天' },
          { value: 5, label: '5天' },
          { value: 10, label: '10天' },
          { value: 30, label: '30天', disabled: true }
        ]
      } else if (toTimestamp(handleDate(curTime, 5)) <= toTimestamp(this.form.deadline)) {
        options = [
          { value: 1, label: '1天' },
          { value: 3, label: '3天' },
          { value: 5, label: '5天' },
          { value: 10, label: '10天', disabled: true },
          { value: 30, label: '30天', disabled: true }
        ]
      } else if (toTimestamp(handleDate(curTime, 3)) <= toTimestamp(this.form.deadline)) {
        options = [
          { value: 1, label: '1天' },
          { value: 3, label: '3天' },
          { value: 5, label: '5天', disabled: true },
          { value: 10, label: '10天', disabled: true },
          { value: 30, label: '30天', disabled: true }
        ]
      } else if (toTimestamp(handleDate(curTime, 1)) <= toTimestamp(this.form.deadline)) {
        options = [
          { value: 1, label: '1天' },
          { value: 3, label: '3天', disabled: true },
          { value: 5, label: '5天', disabled: true },
          { value: 10, label: '10天', disabled: true },
          { value: 30, label: '30天', disabled: true }
        ]
      } else {
        options = [
          { value: 1, label: '1天', disabled: true },
          { value: 3, label: '3天', disabled: true },
          { value: 5, label: '5天', disabled: true },
          { value: 10, label: '10天', disabled: true },
          { value: 30, label: '30天', disabled: true }
        ]
      }
      return options
    },
    // 上传地址
    uploadUrl () {
      return process.env.VUE_APP_API_BASE_URL + 'api/v1/media/put'
    }
  },
  created () {
    if (this.taskId) {
      this.getDetailInfo()
    }
  },
  methods: {
    dateChange () {
      this.form.timeLimit = ''
    },
    backToTaskHall () {
      this.$router.push('/company/manage')
    },
    publishClick () {
      if (this.form.money > this.amountMoney) {
        this.open()
      } else {
        this.publishTask()
      }
    },
    // 获取任务详情
    getDetailInfo () {
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          this.form = data
          this.form.remark = this.form.remark.replace(/<br\/>/g, '\n')
          this.form.require = this.form.require.replace(/<br\/>/g, '\n')
          if (data.attachments) {
            this.form.attachmentIds = data.attachments.map(item => {
              return {
                ...item,
                name: item.originName,
                uid: item.id,
                url: item.downloadUrl
              }
            })
          } else {
            this.form.attachmentIds = []
          }

          this.form.deadline = data.deadline.substr(0, 10)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    },
    // 发布任务
    publishTask () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form.attachmentIds)
          const ids = this.form.attachmentIds.map(item => item.id)
          this.btnLoading = true
          api.publishTask({
            ...this.form,
            remark: this.form.remark = this.form.remark.replace(/\n/g, '<br/>'),
            require: this.form.require = this.form.require.replace(/\n/g, '<br/>'),
            taskType: 0,
            attachmentIds: ids
          }).then(res => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$message.success('发布成功，请等待后台审核')
              this.$router.push('/company/manage')
            } else if (res.data.code === 20001) {
              this.$message.error(res.data.message)
            } else {
              this.$message.error('发布失败')
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    open () {
      this.$confirm('余额不足，无法发布此任务,请充值后发布！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '请充值'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    backId (val) {
      this.form.attachmentIds = val
    },
    // 删除文件
    handleRemove (file, fileList) {
      const index = this.form.attachmentIds.findIndex(item => {
        return item.uid === file.uid
      })
      this.form.attachmentIds.splice(index, 1)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 删除之前
    beforeRemove (file) {
      return this.$confirm(`确定移除 ${file.name}?`)
    },
    // 上传文件之前
    beforeAvatarUpload (file) {
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
    },
    // 上传成功
    fileSuccess (res, file) {
      this.form.attachmentIds.push({
        uid: file.uid,
        name: file.name,
        ...res
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.audit_detail_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  .title_container {
    width: 100%;
    height: 100px;
    min-height: 100px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E7ED;
    .back_to_task {
      width: 100px;
      height: 40px;
      font-size: 14px;
      margin-right: 42px;
      text-align: center;
      line-height: 40px;
      color: #606266;
      cursor: pointer;
    }
    .task_title {
      font-size: 20px;
      color: #303133;
      font-weight: bold;
    }
  }
  .task_form_container {
    width: 100%;
    padding: 30px 50px 0 50px;
    flex: 1;
    overflow: auto;
    .el-form-item {
      margin-bottom: 22px;
      .input_after {
        width: 20px;
        text-align: center;
      }
      .tip {
        font-size: 14px;
        font-weight: 500;
        color: #C0C4CC;
        margin-left: 10px;
      }
    }
  }
  .release_button {
    height: 60px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E4E7ED;
    padding: 0 30px;
  }
}
</style>
