/**
 * 当前时间加上天数得到yyyy-mm-dd
 * @param {*} curDate 当前时间 yyyy-mm-dd
 * @param {*} days 天数
 * @returns
 */
export function handleDate (curDate, days) {
  const dateTemp = curDate.split('-')
  const nDate = new Date(dateTemp[1] + '-' + dateTemp[2] + '-' + dateTemp[0])
  const millSeconds = Math.abs(nDate) + (days * 24 * 60 * 60 * 1000)
  const rDate = new Date(millSeconds)
  const year = rDate.getFullYear()
  let month = rDate.getMonth() + 1
  if (month < 10) month = '0' + month
  var date = rDate.getDate()
  if (date < 10) date = '0' + date
  return (year + '-' + month + '-' + date)
}

/**
 * 获取当前时间yyyy-mm-dd
 * @returns
 */
export function getNowFormatDate () {
  const date = new Date()
  const separator1 = '-'
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  return year + separator1 + month + separator1 + strDate
}

/**
 * 将字符串转化为时间对象的时间戳
 * @param {*} strTime
 * @returns
 */
export function toTimestamp (strTime) {
  if (strTime === '' || strTime === null) return
  return new Date(Date.parse(strTime.replace('/-/g', '/'))).getTime()
}
